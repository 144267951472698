
@import url('https://fonts.googleapis.com/css2?family=Istok+Web:ital,wght@0,400;0,700;1,400;1,700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import 'variables.module.scss';
@import '~react-toastify/dist/ReactToastify.min.css';
html{
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  body{
    font-family: "Lato";
    background-image: url("../assets/images/backgroundImage.svg");
    background-color: $primaryBg;
  }
  :focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
  }
  :focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
}

  }

